import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import {isMobile} from "react-device-detect"

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "heroBackground.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobile: file(relativePath: { eq: "heroBackground_mobile.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const desktopImg = data.desktop.childImageSharp.fluid
  const mobileImg = data.mobile.childImageSharp.fluid

  if(isMobile) {
    return <BackgroundImage
      Tag="section"
      className={className}
      fluid={mobileImg}
      backgroundColor={`#FD91A6`}
    >
      {children}
    </BackgroundImage>
  }

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={desktopImg}
      backgroundColor={`#FD91A6`}
    >
      {children}
    </BackgroundImage>
  )
}

const HeroBackgroundImage = styled(BackgroundSection)`
  width: 100%;
  height: 100vh;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default HeroBackgroundImage