import React from 'react';
import {isMobile} from "react-device-detect"
import WelcomeIcon from "../../../content/assets/heroWelcome.svg"
import WelcomeIconMobile from "../../../content/assets/heroWelcome_mobile.svg"

export default function HeroWelcome() {
  if(isMobile) {
    return <WelcomeIconMobile/>
  }
  return <WelcomeIcon/>;
}