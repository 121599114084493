import React from "react";
import {Link} from "gatsby";
import { BiDownArrowCircle } from "react-icons/bi"

export default function HeroMenuLink({to, classes, textColor, children}) {

  return (

    <div className={classes}>
      <Link to={to} className="has-text-weight-semibold is-size-7-mobile is-size-4-desktop" style={{color: textColor, verticalAlign: "baseline"}}>
        <span className="icon-text">
        <span className="icon">
          <BiDownArrowCircle style={{width: "1.5em", height: "1.5em", color: textColor}}/>
        </span>
        <span>{children}</span>
      </span>
      </Link>
    </div>

  )

}