import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "experienceBg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const img = data.desktop.childImageSharp.fluid

    return <BackgroundImage
      Tag="section"
      className={className}
      fluid={img}
      backgroundColor={`#FD91A6`}
    >
      {children}
    </BackgroundImage>
}

const ExperienceBgImage = styled(BackgroundSection)`
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default ExperienceBgImage