import React from "react";
import styled from "styled-components"

export default function RoundedBox({width, height, bgColor, children}) {

    const Box = styled(props => <div {...props} />)`
      background-color: ${bgColor};
      width: ${width};
      height: ${height};
  `;

  return (
    <Box className="box p-5">
      {children}
    </Box>
  )

}