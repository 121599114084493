import React, { Component } from "react";

// image files
import { FaWordpress, FaGithub, FaNpm  } from 'react-icons/fa';
import { DiJavascript, DiNodejsSmall, DiCss3, DiHtml5, DiReact } from 'react-icons/di';
import { SiAdobeaftereffects, SiAdobexd, SiAdobephotoshop, SiAdobeillustrator, SiAdobeindesign } from 'react-icons/si';

class ReactIcon extends Component {

  components = {

    ae: SiAdobeaftereffects,
    css: DiCss3,
    github: FaGithub,
    html5: DiHtml5,
    illustrator: SiAdobeillustrator,
    indesign: SiAdobeindesign,
    javascript: DiJavascript,
    wordpress: FaWordpress,
    node: DiNodejsSmall,
    npm: FaNpm,
    photoshop: SiAdobephotoshop,
    react: DiReact,
    xd: SiAdobexd

  }

  render() {
    const TagName = this.components[this.props.tag];
    const textColor = this.props.color;
    return <TagName style={{color: textColor, width: "100%", height: "100%"}}/>;
  }

}

export default ReactIcon;