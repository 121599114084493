import React from "react";
import {Link} from "gatsby";
import RoundedBox from "./roundedBox"
import ReactIcon from "../icons"

export default function About() {

  const sectionBgColor = "#000000";
  const boxWidth = "100px";
  const boxHeight = "112px";
  const boxBgColor = "#F24D9F";
  const iconColor = "#ffffff";

  const icons = ["html5", "css", "javascript", "node", "react", "photoshop", "illustrator", "ae"];

  return (

    <section id="about" key="about" className="section pt-6 pb-6" style={{backgroundColor: sectionBgColor}}>
      <div className="container">
        <div className="columns is-variable">
          <div className="column is-hidden-mobile">
            <div className="columns is-mobile is-variable is-multiline">
              {icons.map(icon => (
              <div className="column is-one-third-mobile is-one-quarter-tablet is-centered">
                <RoundedBox bgColor={boxBgColor} width={boxWidth} height={boxHeight}><ReactIcon tag={icon} color={iconColor}/></RoundedBox>
              </div>
              ))}
            </div>
          </div>
          <div className="column">
            <h2 className="title is-2 has-text-white is-family-primary has-text-weight-bold mb-4">ABOUT ME</h2>
            <p className="has-text-white mb-5">I’m a full-stack developer and digital designer who creates intuitive
              web applications, elegant UI, compelling video, and on-target branding for
              multinational pharmaceutical corporations, two person non-profit organizations,
              and everyone in-between.</p>
            <Link to="/#experience" className="button">My Experience</Link>
          </div>
        </div>
      </div>
    </section>

  )

}