import React from "react";
import { Link } from "gatsby"

const _ = require("lodash");

export default function ExperienceBox({jobTitle, company, dates, location, children, tags, bgColor}) {

  const boxTags = tags.split(", ");

  return (
    <div className="column is-half-desktop">
    <div className="box" style={{backgroundColor: bgColor}}>
      <div className="title is-5 is-family-primary has-text-grey-dark mb-1">{jobTitle}</div>
      <div className="is-family-secondary is-size-6">{company}</div>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between mt-2">
        <div className="has-text-left">
          <div className="is-family-secondary is-size-7">{dates}</div>
        </div>
        <div className="has-text-right">
          <div className="is-family-secondary is-size-7">{location}</div>
        </div>
      </div>
      <div className="is-family-secondary is-size-7 mt-3 content">{children}</div>
      <div className="mt-2">
        {boxTags.map(tag => (
          <Link to={`/tag/${_.kebabCase(tag.toString())}`}><span key={`${tag}`} className="tag mr-1">{tag.toUpperCase()}</span></Link>
        ))}
      </div>

    </div>
    </div>

  )

}