import React from "react";
import "./styles.scss";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import About from "../components/about/about";
import Projects from "../components/projects/projects";
import { graphql } from "gatsby";
import Hero from "../components/hero/hero";
import Experience from "../components/experience/experience";

const Index = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Nate Walters`;
  const siteDescription = data.site.siteMetadata?.description || `Nate Walters, Web Developer And Digital Designer`;

  return (
    <Layout>
      <div className="is-family-secondary">
      <SEO title={ siteTitle } description={ siteDescription } page="home"/>
      <Hero/>
      <About />
      <Projects/>
      <Experience/>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
