import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProjectCard from "./projectCard"

const Projects = () => {

  const data = useStaticQuery(graphql`
  query ProjectsQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            category
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`)
  console.log(data);
  const posts = data.allMarkdownRemark.edges;

  return (

    <section id="projects" key="projects" className="section">
      <div className="container">
        <h2 className="title is-2 has-text-grey-dark">SELECTED PROJECTS</h2>
        <div className="columns is-multiline is-1-mobile is-4-tablet">

          {posts.map(node => {
            console.log(node);
            return (
              <div className="column is-full-mobile is-one-third-tablet">
                <ProjectCard node={node}/>
              </div>
            )
          })}

        </div>
      </div>
    </section>

  )

}

export default Projects

