import React from "react";
import HeroMenuLink from "./heroMenuLink";

export default function HeroMenu() {

  const classes = "column";
  const textColor = "#1A1464";

  return (

    <div className="columns is-mobile is-variable is-0 is-centered ml-2 mt-3">
      <HeroMenuLink to={"/#about"} classes={classes} textColor={textColor}>ABOUT</HeroMenuLink>
      <HeroMenuLink to={"/#projects"} classes={classes} textColor={textColor}>PROJECTS</HeroMenuLink>
      <HeroMenuLink to={"/#experience"} classes={classes} textColor={textColor}>EXPERIENCE</HeroMenuLink>
    </div>

  )

}