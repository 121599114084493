import React from "react";
import HeroWelcome from "./heroWelcome"
import HeroBackgroundImage from "./heroBackgroundImage"
import { motion, useViewportScroll, useTransform } from "framer-motion";
import HeroMenu from "./heroMenu"

export default function Hero() {
  const { scrollYProgress } = useViewportScroll();
  const opacityRange = [1, 0];
  const scrollRange = [0, 0.25];
  const opacity = useTransform(scrollYProgress, scrollRange, opacityRange);
  const yPosAnim = useTransform(scrollYProgress, scrollRange, [0, 100]);

  return (
    <HeroBackgroundImage>
    <section id="home" className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <motion.div
            style={{
              opacity,
              y: yPosAnim
            }}
          >
            <HeroWelcome/>
            <HeroMenu/>
          </motion.div>
        </div>
      </div>
    </section>
    </HeroBackgroundImage>
  )

}