import React from "react";
import ExperienceBox from "./experienceBox"
import ExperienceBgImage from "./experienceBackgroundImage"

export default function Experience() {

  return (

    <ExperienceBgImage>
    <section id="experience" className="section">
      <div className="container">
        <h2 className="title is-2 has-text-warning-dark">EXPERIENCE</h2>
        <div className="columns is-2-desktop is-multiline">

          <ExperienceBox
            jobTitle="Founder, Web Developer, Senior Content Producer"
            company="New Sprouts Media, LLC"
            dates="May 2014 - Present"
            location="Sedro-Woolley, WA"
            tags="development, design, video, audio, voiceover, adobe creative suite, nodejs, react, javascript, html, css, gatsby, wordpress, project management"
            bgColor="#fafafa"
          >
            New Sprouts Media is a digital design and development company that focuses on user engagement and quality of content as a means of reaching new and untapped target audiences.
            <ul>
              <li>Designed and developed SEO-friendly websites that, on average, increased traffic by 476%</li>
              <li>Developed social media marketing campaigns and materials that increased engagement by an average of 112%</li>
              <li>Provided graphic and logo design services to improve brand visibility and affinity</li>
              <li>Worked directly with stakeholders to create visually distinctive marketing materials</li>
              <li>Provided script-writing and motion graphics production for the launch of a multi-million dollar pharmaceutical product</li>
            </ul>
          </ExperienceBox>

          <ExperienceBox
            jobTitle="Web Architect, Graphic Designer"
            company="The Evergreen Foundation"
            dates="June 2014 - Present"
            location="Coeur d'Alene, ID (Remote)"
            tags="development, design, video, audio, voiceover, adobe creative suite, gatsby, wordpress, project management"
            bgColor="#fafafa"
          >
            The Evergreen Foundation is a non-profit forestry research and educational organization whose mission is to help advance public understanding and support for science-based forestry and forest policy.
            <ul>
              <li>Designed and built SEO-friendly website (evergreenmagazine.com) that increased online traffic by 2,327%</li>
              <li>Created updated brand profile, including new logo and style guide</li>
              <li>Worked directly with ownership and other stakeholders to design and create graphic and video content for "Counties on Fire" and "Anchor Forest" projects</li>
            </ul>
          </ExperienceBox>

          <ExperienceBox
            jobTitle="UX & Digital Designer, Project Manager, Front-End Developer"
            company="Spladap, Inc"
            dates="May 2017 - Mar 2018"
            location="Bellevue, WA (Remote)"
            tags="ux, ui design, graphic design, project management, front-end dev, adobe creative suite, jsp, html, css, wordpress"
            bgColor="#fafafa"
          >
            Spladap is a SaaS, interactive sports training platform that connects athletes with top-rated trainers across the country. Through the use of data and technology, the platform combines real-time analysis of the athlete and a repository of content and lesson plans to create a training regime specific to the needs of the athlete at that moment.
            <ul>
              <li>Acted as project managing liason between ownership, sales, design, and development</li>
              <li>Created hundreds of page and state designs for admin/trainer dashboard and onboarding/signup process</li>
              <li>Designed and deployed individual trainer splash pages</li>
              <li>Assisted Lead Developer with front-end development, including creation of JSP templates using Bootstrap framework</li>
              <li>Worked directly with sales team to create marketing materials and presentations</li>
            </ul>
          </ExperienceBox>

          <ExperienceBox
            jobTitle="Senior Designer, Front-End Developer"
            company="Clinaero, Inc"
            dates="Nov 2004 - May 2014"
            location="Bellevue, WA"
            tags="ui design, motion graphics, front-end dev, video, audio, voiceover, ux, graphic design, adobe creative suite, actionscript, html, css, jsp"
            bgColor="#fafafa"
          >
            Clinaero, Inc. is a web and software company that launched one of the top two consumer health information domains for organic reach (eMedTV.com).
            <ul>
              <li>Developed the UX and SEO strategy for eMedTV.com, reaching more than 5 million unique visitors a month in just three years -- with $0 spent on avertising</li>
              <li>Designed and developed (ActionScript) front end of custom flash video player that streamed more than 80 million videos and ads</li>
              <li>Wrote scripts, edited and produced audio and video, and created motion graphics for approximately 3,500 videos that have educated more than 50 million people about over 200 health topics</li>
              <li>Worked directly with sales team to create over 400 sales presentations and advertising proposals that resulted in an estimated $25 million in revenue</li>
              <li>Coordinated with sales, development, and content teams to design and create graphic, text, and video content, as well as health tools, for 20 sponsored microsites that brought in an estimated $6 million in revenue</li>
            </ul>
          </ExperienceBox>

        </div>
      </div>

    </section>
    </ExperienceBgImage>
  )

}